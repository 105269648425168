export const configColumnPartner = [
  {
    key: 'requested_date',
    label: 'Tanggal',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
      minWidth: '200px',
      textAlign: 'center',
    },
    tdClass: 'text-black',
    thClass: 'font-semibold',
    class: 'p-1',
  },
  {
    key: 'partner_name',
    label: 'Nama',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
      minWidth: '200px',
      textAlign: 'center',
    },
    tdClass: 'text-black',
    thClass: 'font-semibold',
    class: 'p-1',
  },
  {
    key: 'product_of',
    label: 'Produk',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
      minWidth: '200px',
      textAlign: 'center',
    },
    class: 'p-1',
  },
  {
    key: 'phone_number',
    label: 'Kontak',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
      minWidth: '150px',
      textAlign: 'center',
    },
    tdClass: 'text-black text-center',
    thClass: 'font-semibold',
    class: 'p-1',
  },
  {
    key: 'file',
    label: 'Berkas',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
      minWidth: '150px',
      textAlign: 'center',
    },
    tdClass: 'text-black text-center',
    thClass: 'font-semibold',
    class: 'p-1',
  },
  {
    key: 'history',
    label: 'Riwayat Pengajuan',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
      minWidth: '200px',
      textAlign: 'center',
    },
    tdClass: 'text-black text-center',
    thClass: 'font-semibold',
    class: 'p-1',
  },
  {
    key: 'status',
    label: 'Status',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
      minWidth: '100px',
      textAlign: 'center',
    },
    tdClass: 'text-black',
    thClass: 'font-semibold',
    class: 'p-1',
  },
]

export const configColumnHistory = [
  {
    key: 'requested_date',
    label: 'Tanggal',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
      minWidth: '200px',
      textAlign: 'center',
    },
    tdClass: 'text-black',
    thClass: 'font-semibold',
    class: 'p-1',
  },
  {
    key: 'ktp_face_picture_notes',
    label: 'Catatan Foto Selfie',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
      textAlign: 'center',
    },
    tdClass: 'text-black',
    thClass: 'font-semibold',
    class: 'p-1 max-w-xs min-w-[200px]',
  },
  {
    key: 'ktp_picture_notes',
    label: 'Catatan Foto KTP',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
      textAlign: 'center',
    },
    tdClass: 'text-black text-center',
    thClass: 'font-semibold',
    class: 'p-1 max-w-xs min-w-[200px]',
  },
  {
    key: 'file',
    label: 'Berkas',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
      minWidth: '150px',
      textAlign: 'center',
    },
    tdClass: 'text-black text-center',
    thClass: 'font-semibold',
    class: 'p-1',
  },
  {
    key: 'status',
    label: 'Status',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
      minWidth: '100px',
      textAlign: 'center',
    },
    tdClass: 'text-black',
    thClass: 'font-semibold',
    class: 'p-1',
  },
]

export const chooseStatus = [
  {
    value: null,
    text: 'Pilih Status',
    disabled: true,
  },
  {
    text: 'Semua',
    value: '',
  },
  {
    text: 'Request Pengajuan',
    value: 'requested',
  },
  {
    text: 'Disetujui',
    value: 'approved',
  },
  {
    text: 'Ditolak',
    value: 'rejected',
  },
]

export const rules = [
  {
    id: 1,
    status: true,
    icon: 'check-circle-fill',
    image: [
      {
        id: 1,
        url:
          'https://storage.googleapis.com/komerce/assets/illustration/photo-selfie-ktp.jpg',
        width: 100,
      },
      {
        id: 2,
        url:
          'https://storage.googleapis.com/komerce/assets/illustration/sample-ktp.jpg',
        width: 100,
      },
    ],
    bodyClass: 'bg-[#DCF3EB] rounded-md',
    classStatus: 'text-emerald-700 ml-1',
    classTitle: 'text-emerald-700 font-medium mt-1',
    classLabel: 'font-normal text-emerald-600',
    variant: 'success',
    title: 'Status verifikasi KTP diterima apabila :',
    main_rules: [
      { id: 1, label: 'Selfie + KTP terfoto dengan jelas dan tidak terpotong' },
      {
        id: 2,
        label: 'Data yang ada di profile sesuai dengan data yang ada di KTP',
      },
    ],
  },
  {
    id: 2,
    status: false,
    icon: 'x-circle-fill',
    image: [
      {
        id: 1,
        url:
          'https://storage.googleapis.com/komerce/assets/illustration/photo-selfie-ktp-2.jpg',
        width: 100,
      },
      {
        id: 2,
        url:
          'https://storage.googleapis.com/komerce/assets/illustration/sample-ktp-blur.jpg',
        width: 100,
      },
    ],
    bodyClass: 'bg-[#FFEDED] rounded-md',
    classStatus: 'text-rose-700 ml-1',
    classTitle: 'text-rose-700 font-medium mt-1',
    classLabel: 'font-normal text-rose-600',
    variant: 'danger',
    title: 'Status verifikasi KTP ditolak apabila :',
    main_rules: [
      { id: 1, label: 'Selfie + KTP terfoto tidak jelas dan terpotong' },
      {
        id: 2,
        label:
          'Data yang ada di profile tidak sesuai dengan data yang ada di KTP',
      },
    ],
  },
]

export const optionsRadioSelfie = [
  { text: 'Disetujui', value: true },
  { text: 'Ditolak', value: false },
]

export const getLabelStatus = value => {
  if (value === 'requested') return 'Request'
  if (value === 'approved') return 'Disetujui'
  if (value === 'rejected') return 'Ditolak'
  return ''
}

export const getClassStatus = value => {
  if (value === 'requested') return 'px-1 py-[5px] text-center text-[#FBA63C] rounded-md border-[#FBA63C] bg-[#FFF2E2]'
  if (value === 'approved') return 'px-1 py-[5px] text-center text-[#34A770] rounded-md border-[#34A770] bg-[#DCF3EB]'
  if (value === 'rejected') return 'px-1 py-[5px] text-center text-[#E31A1A] rounded-md border-[#E31A1A] bg-[#FFEDED]'
  return ''
}

export const getClass = value => {
  if (value === 'requested') return 'text-[#FBA63C]'
  if (value === 'approved') return 'text-[#34A770]'
  if (value === 'rejected') return 'text-[#E31A1A]'
  return ''
}

export const iconProduct = [
  { name: 'is_komship', src: 'https://storage.googleapis.com/komerce/icon/komship-with-fill.svg', alt: 'komship' },
  { name: 'is_komcards', src: 'https://storage.googleapis.com/komerce/icon/komcards-with-fill.svg', alt: 'komcards' },
  { name: 'is_komtim', src: 'https://storage.googleapis.com/komerce/icon/komtim-with-fill.svg', alt: 'komtim' },
  { name: 'is_komplace', src: 'https://storage.googleapis.com/komerce/icon/komplace-with-fill.svg', alt: 'komplace' },
  { name: 'is_komform', src: 'https://storage.googleapis.com/komerce/icon/komform-with-fill.svg', alt: 'komfrom' },
  { name: 'is_affiliate', src: 'https://storage.googleapis.com/komerce/icon/affiliate-with-fill.svg', alt: 'affiliate' },
  { name: 'is_pendamping_umkm', src: 'https://storage.googleapis.com/komerce/icon/pumkm-with-fill.svg', alt: 'pumkm' },
]

export const optionsProduct = [
  {
    value: null,
    text: 'Pilih Product',
    disabled: true,
  },
  {
    value: '',
    text: 'Semua',
  },
  {
    value: 'komship',
    text: 'Komship',
  },
  {
    value: 'komcards',
    text: 'Komcards',
  },
]
